.swiper-container.feedback-swiper {
  width: 100%;
  /* height: 100%; */
  padding: 0;
}

/* .swiper-container.feedback-swiper .swiper-button-prev {
  left: 0;
  right: auto;
}
.swiper-container.feedback-swiper .swiper-button-next {
  right: 0;
  left: auto;
} */

.swiper-container.feedback-swiper .swiper-wrapper {
  margin-bottom: 3.5rem;
}

.swiper-container.feedback-swiper .swiper-slide {
  /* min-width: 350px;
  max-width: 375px; */
  width: 100%;
  min-height: 500px;
  border-radius: 8px;
  /* border: 1px solid pink; */
  text-align: center;
  /* font-size: 18px; */
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

/* .swiper-container.feedback-swiper .swiper-slide img {
  all: unset;
} */
@media screen and (max-width: 1000px) {
    .swiper-container.feedback-swiper .swiper-slide {
        min-height: 530px;
    }
}
@media screen and (max-width: 959px) {
    .swiper-container.feedback-swiper .swiper-slide {
        min-height: 500px;
    }
}
@media screen and (max-width: 660px) {
    .swiper-container.feedback-swiper .swiper-slide {
        min-height: 450px;
    }
}
@media screen and (max-width: 480px) {
    .swiper-container.feedback-swiper .swiper-slide {
        min-height: 500px;
    }
}
@media screen and (max-width: 380px) {
    .swiper-container.feedback-swiper .swiper-slide {
        min-height: 530px;
    }
}