/*Đã bỏ, ko dùng nữa*/
@font-face {
    font-family: 'Destain';
    src: local('Destain'), url(./assets/fonts/Destain/Destain-Xgma.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
    font-family: 'Plaza';
    src: local('Plaza'), url(./assets/fonts/Plaza/PlazaITC-Art.ttf) format('truetype');
}
@font-face {
    font-family: 'BlackberryJam';
    src: local('BlackberryJam'), url(./assets/fonts/BlackberryJam/BlackberryJam.ttf) format('truetype');
}