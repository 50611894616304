.swiper-container {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 3.5rem;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 330px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}